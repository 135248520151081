import {
	EMAIL_LABEL,
	EMAIL_ERROR,
	TRY_AGAIN,
	GO_BACK,
	SIGN_IN,
	SEND_PIN,
	SUBMIT_FORM,
	NAME_LABEL,
	LASTNAME_LABEL,
	CELLPHONE_LABEL,
	COMPANY_NAME_LABEL,
	JOB_TITLE_LABEL,
	WRONG_DATA_ERROR,
	CONTINUE_TEXT,
	CREATE_ACCOUNT_TEXT,
	SELECT_LANGUAGE_TEXT,
	EMPTY_FIELD,
	INVALID_EMAIL_FORMAT,
	USER_LABEL,
	PASSWORD_LABEL,
	INVALID_PHONE_NUMBER,
	ERROR_MESSAGE,
	TRY_AGAIN_TEXT,
} from '../translation_keys';

export default {
	[EMAIL_LABEL]: 'Email',
	[EMAIL_ERROR]: 'The entered email does not have an associated account.',
	[TRY_AGAIN]: 'An error occurred, please try again.',
	[GO_BACK]: 'Go back',
	[SIGN_IN]: 'Sign in',
	[SEND_PIN]: 'Send PIN',
	[SUBMIT_FORM]: 'Submit',
	[NAME_LABEL]: 'First Name',
	[LASTNAME_LABEL]: 'Last Name',
	[CELLPHONE_LABEL]: 'Cell Phone Number',
	[COMPANY_NAME_LABEL]: 'Company Name',
	[JOB_TITLE_LABEL]: 'Your jobtitle',
	[WRONG_DATA_ERROR]: 'An error has occurred, please review the entered data.',
	[CONTINUE_TEXT]: 'Continue',
	[CREATE_ACCOUNT_TEXT]: 'Create account',
	[SELECT_LANGUAGE_TEXT]: 'Select the language',
	[EMPTY_FIELD]: 'This field cannot be empty',
	[INVALID_EMAIL_FORMAT]: 'Invalid email. Please try again',
	[USER_LABEL]: 'Username',
	[PASSWORD_LABEL]: 'Password',
	[INVALID_PHONE_NUMBER]: 'Invalid phone number. Please try again',
	[ERROR_MESSAGE]: 'An error occurred',
	[TRY_AGAIN_TEXT]: 'Please try again later',
};
