export const loginFields = {
	EMAIL: 'email',
};

export const enterpriseLoginFields = {
	USERNAME: 'username',
	PASSWORD: 'password',
};

export const SECONDS_TO_SHOW_RESEND_MESSAGE = 30;
