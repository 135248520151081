import * as yup from 'yup';
import { welcomeFields } from '../constants/welcome';
import { EMPTY_FIELD } from '../../../../shared/infrastructure/i18n/locales/translation_keys';

export const welcomeValidation = {
	[welcomeFields.COMPANY]: yup.string().required(EMPTY_FIELD),
	[welcomeFields.COMPANY_POSITION]: yup.string().required(EMPTY_FIELD),
};

export const welcomeSchema = yup.object().shape(welcomeValidation);
