import { LOGIN, FOOTER, SIGN_UP, TERMS, OTP_VALIDATION } from '../translation_keys';

export default {
	[LOGIN.TITLE]: `Log in`,
	[LOGIN.TEXT_INFO]: `Connect with the Vecindario Suite platform to start managing your businesses, clients, and partners`,
	[LOGIN.EMAIL]: `Log in`,
	[LOGIN.GOOGLE]: `Sign in with Google`,
	[LOGIN.MICROSOFT]: `Sign in with Microsoft`,
	[LOGIN.ENTERPRISE]: `Business account`,
	[FOOTER.LOGIN_TEXT]: `Don't have an account?`,
	[FOOTER.LOGIN_LINK_TEXT]: `Sign up`,
	[FOOTER.SIGN_UP_TEXT]: `Already have an account?`,
	[FOOTER.SIGN_UP_LINK_TEXT]: `Login here`,
	[SIGN_UP.TITLE]: `Create your account`,
	[SIGN_UP.TEXT_INFO]: `Enter your information to create your account`,
	[SIGN_UP.EMAIL]: `Sign up with your email`,
	[SIGN_UP.GOOGLE]: `Sign up with Google`,
	[SIGN_UP.MICROSOFT]: `Sign up with Microsoft`,
	[SIGN_UP.ENTERPRISE]: `Business account`,
	[SIGN_UP.TITLE_WELCOME]: `Welcome to Vecindario Suite!`,
	[SIGN_UP.TEXT_WELCOME]: `To continue, we need information about the construction company you work for and the jobtitle you hold in it`,
	[SIGN_UP.TRIAL_TEXT]: `30 days free! Afterwards, you can choose the plan you like the most`,
	[SIGN_UP.ACCOUNT_ALREADY_EXIST]: `The account cannot be created; there may already be an account with that email. Try logging in <a href="/iniciar-sesion">here</a>.`,
	[TERMS.ACCEPT]: `By continuing, you accept our`,
	[TERMS.TEMS_AND_CONDITIONS]: `Terms and Conditions`,
	[TERMS.AUTHORIZE]: `and our`,
	[TERMS.PRIVACY_POLICY]: `Privacy Policy.`,
	[OTP_VALIDATION.TEXT]: `We sent you a code to your email:`,
	[OTP_VALIDATION.NO_EMAIL]: `Not your email?`,
	[OTP_VALIDATION.WRONG_OTP]: `Something went wrong, please verify the entered code`,
	[OTP_VALIDATION.OTP_EXPIRED]: `The code has expired, please request a new one.`,
	[OTP_VALIDATION.ATTEMPS_ENOUGH]: 'You have exceeded the number of attempts, please request a new access code.',
};
