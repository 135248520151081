import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { Button } from '@vecindario/vecindario-suite-components';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../infrastructure/i18n/locales';
import { ERROR_MESSAGE, GO_BACK, TRY_AGAIN_TEXT } from '../../../infrastructure/i18n/locales/translation_keys';
import { getValueFromLocalStorage, removeValueFromLocalStorage } from '../../../application/helpers/common-functions';
import { ERROR_RETURN_URL } from '../../../application/constants/queryParamsKeys';
import { homeRoute } from '../../../infrastructure/routing/routes';
import './ErrorPage.scss';

const ErrorPage = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const errorReturnUrl = getValueFromLocalStorage(ERROR_RETURN_URL);

	const handleBack = () => {
		if (errorReturnUrl) {
			removeValueFromLocalStorage(ERROR_RETURN_URL);
			window.location.replace(errorReturnUrl);
		} else {
			history.push(homeRoute);
		}
	};

	return (
		<div className="error-page-container">
			<div className="info-container">
				<img src="https://suite-frontend-sso.s3.us-east-2.amazonaws.com/cactus.svg" />
				<p>{t(`${ERROR_MESSAGE}`, { ns: DOMAIN_NAME_SHARED })}</p>
				<p>{t(`${TRY_AGAIN_TEXT}`, { ns: DOMAIN_NAME_SHARED })}</p>
				<Button text={t(`${GO_BACK}`, { ns: DOMAIN_NAME_SHARED })} size="-medium" onClick={handleBack} />
			</div>
		</div>
	);
};

export default ErrorPage;
