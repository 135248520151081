import * as yup from 'yup';
import { registerFields } from '../constants/register';
import {
	EMPTY_FIELD,
	INVALID_EMAIL_FORMAT,
	INVALID_PHONE_NUMBER,
} from '../../../../shared/infrastructure/i18n/locales/translation_keys';

export const registerValidation = {
	[registerFields.NAME]: yup.string().required(EMPTY_FIELD),
	[registerFields.LAST_NAME]: yup.string().required(EMPTY_FIELD),
	[registerFields.PHONE_NUMBER]: yup.string().required(INVALID_PHONE_NUMBER),
	[registerFields.EMAIL]: yup.string().email(INVALID_EMAIL_FORMAT).required(EMPTY_FIELD),
	[registerFields.ACCEPT_EMAILS_NOTIFICATIONS]: yup.bool().nullable(),
};

export const registerSchema = yup.object().shape(registerValidation);
