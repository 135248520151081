import { Route } from 'react-router-dom';
import AdminLayout from '../../../shared/presentation/layouts/AdminLayout';
import { UnauthenticatedRoute, PrivateRoute } from '../../../shared/presentation/redirect-route';
import RegisterPage from '../presentation/pages/register';
import {
	enterpriseLoginRoute,
	loginReceiveCodeRoute,
	loginRoute,
	receiveCodeRoute,
	registerRoute,
	updateDataRoute,
	welcomeRoute,
	acceptInvitationRoute,
	logoutRoute,
} from './routes';
import ReceiveCode from '../presentation/pages/receiveCode';
import Welcome from '../presentation/pages/welcome';
import Login from '../presentation/pages/login';
import { homeRoute } from '../../../shared/infrastructure/routing/routes';
import Redirect from '../../../shared/presentation/pages/StartRedirect';
import EnterpriseLoginPage from '../presentation/pages/EnterpriseLogin';
import AcceptInvitation from '../presentation/pages/AcceptInvitation';
import LogoutPage from '../presentation/pages/Logout';

const authRouter = {
	layout: AdminLayout,
	router: [
		{
			path: homeRoute,
			page: Redirect,
			routeComponent: UnauthenticatedRoute,
		},
		{
			path: loginRoute,
			page: Login,
			routeComponent: UnauthenticatedRoute,
		},
		{
			path: registerRoute,
			page: RegisterPage,
			routeComponent: UnauthenticatedRoute,
		},
		{
			path: receiveCodeRoute,
			page: ReceiveCode,
			routeComponent: UnauthenticatedRoute,
		},
		{
			path: welcomeRoute,
			page: Welcome,
			routeComponent: UnauthenticatedRoute,
		},
		{
			path: updateDataRoute,
			page: Welcome,
			routeComponent: PrivateRoute,
		},
		{
			path: loginReceiveCodeRoute,
			page: ReceiveCode,
			routeComponent: UnauthenticatedRoute,
		},
		{
			path: enterpriseLoginRoute,
			page: EnterpriseLoginPage,
			routeComponent: UnauthenticatedRoute,
		},
		{
			path: acceptInvitationRoute,
			page: AcceptInvitation,
			routeComponent: Route,
		},
		{
			path: logoutRoute,
			page: LogoutPage,
			routeComponent: Route,
		},
	],
};

export default authRouter;
