import { emailProviders } from './index';
import { GOOGLE_LOGO, MICROSOFT_LOGO } from '../../../authentication/application/constants/images';
import { postGoogleLogin, postMicrosoftLogin } from '../../infrastructure';
import { microsoftSyncUrl } from '../helpers/microsoft';
import { googleSyncUrl } from '../helpers/google';
import {
	LOGIN_GOOGLE_UUID_KEY,
	LOGIN_MICROSOFT_UUID_KEY,
} from '../../../../shared/application/constants/localStorageKeys';

export const SOCIAL_AUTH_LOGO = {
	[emailProviders.MICROSOFT]: MICROSOFT_LOGO,
	[emailProviders.GOOGLE]: GOOGLE_LOGO,
};

export const SOCIAL_AUTH_BACKEND_FUNCTION = {
	[emailProviders.MICROSOFT]: postMicrosoftLogin,
	[emailProviders.GOOGLE]: postGoogleLogin,
};

export const SOCIAL_AUTH_REDIRECT_URL = {
	[emailProviders.MICROSOFT]: microsoftSyncUrl,
	[emailProviders.GOOGLE]: googleSyncUrl,
};

export const SOCIAL_AUTH_UUID_KEY = {
	[emailProviders.MICROSOFT]: LOGIN_MICROSOFT_UUID_KEY,
	[emailProviders.GOOGLE]: LOGIN_GOOGLE_UUID_KEY,
};
