import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import Proptypes from 'prop-types';
import {
	getReturnUrl,
	getSuiteUserCookie,
	windowsRedirect,
	getValueFromLocalStorage,
	checkCookieExistence,
} from '../application/helpers/common-functions';
import { loginRoute, updateDataRoute } from '../../domains/authentication/infrastructure/routes';
import { SOCIAL_AUTH_REGISTER, LAST_SELECTED_SLUG } from '../application/constants/localStorageKeys';

const tokenInLocalStorage = (authRegister, history) => {
	const user = getSuiteUserCookie();
	if (getValueFromLocalStorage(SOCIAL_AUTH_REGISTER) && authRegister) return history.push(updateDataRoute);
	return user ? JSON.parse(user)?.token : false;
};

export const UnauthenticatedRoute = ({ component: C, layout: Layout, ...rest }) => {
	const history = useHistory();
	const user = getSuiteUserCookie();
	const role = JSON.parse(user)?.role;
	const modulesByUser = JSON.parse(user)?.modules_by_project;
	const disable_main_dashboard_access = JSON.parse(user)?.disable_main_dashboard_access;
	const slugFirstProject = checkCookieExistence(LAST_SELECTED_SLUG) || JSON.parse(user)?.slug_first_project;

	return (
		<Route
			{...rest}
			render={(props) =>
				!tokenInLocalStorage(true, history) ? (
					<Layout path={rest.path}>
						<C {...props} />
					</Layout>
				) : (
					windowsRedirect(`${getReturnUrl(role, false, slugFirstProject, modulesByUser, disable_main_dashboard_access)}`)
				)
			}
		/>
	);
};

export const PrivateRoute = ({ component: C, layout: Layout, ...rest }) => {
	const history = useHistory();
	return (
		<Route
			{...rest}
			render={(props) =>
				tokenInLocalStorage(false) ? (
					<Layout path={rest.path}>
						<C {...props} />
					</Layout>
				) : (
					history.push(loginRoute)
				)
			}
		/>
	);
};

UnauthenticatedRoute.propTypes = {
	component: Proptypes.elementType.isRequired,
	layout: Proptypes.elementType.isRequired,
};

PrivateRoute.propTypes = {
	component: Proptypes.elementType.isRequired,
	layout: Proptypes.elementType.isRequired,
};

export default {};
