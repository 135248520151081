import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	INVITATION_TOKEN_PARAM,
	NEW_ACCOUNT_PARAM,
	ROLE_PARAM,
	PROJECT_SLUG_PARAM,
} from '../../../application/constants/query-params';
import { useQueryParams } from '../../../../../shared/presentation/hooks/useQueryParams';
import { loginRoute, registerRoute } from '../../../infrastructure/routes';
import {
	createSuiteCookie,
	getReturnUrl,
	getSuiteUserCookie,
	setValueToLocalStorage,
	windowsRedirect,
} from '../../../../../shared/application/helpers/common-functions';
import {
	INVITATION_PROJECT_SLUG,
	INVITATION_ROLE,
	INVITATION_TOKEN,
	LAST_SELECTED_SLUG,
} from '../../../../../shared/application/constants/localStorageKeys';
import LoadingPage from '../../../../../shared/presentation/components/LoadingPage';
import { postAcceptInvitation } from '../../../infrastructure/api';

const AcceptInvitation = () => {
	const history = useHistory();
	const invitationToken = useQueryParams(INVITATION_TOKEN_PARAM);
	const newAccount = useQueryParams(NEW_ACCOUNT_PARAM);
	const role = useQueryParams(ROLE_PARAM);
	const slug = useQueryParams(PROJECT_SLUG_PARAM);
	const user = JSON.parse(getSuiteUserCookie());
	const [isAcceptingInvitation, setIsAcceptingInvitation] = useState(false);

	useEffect(() => {
		if (!invitationToken) {
			history.replace(loginRoute);
		}

		setValueToLocalStorage(INVITATION_ROLE, role);
		setValueToLocalStorage(INVITATION_TOKEN, invitationToken);
		setValueToLocalStorage(INVITATION_PROJECT_SLUG, slug);
		createSuiteCookie(LAST_SELECTED_SLUG, slug);

		if (newAccount === 'true') {
			history.replace(registerRoute);
		}

		if (user) {
			if (!isAcceptingInvitation) {
				setIsAcceptingInvitation(true);
				postAcceptInvitation(invitationToken)
					.then(() => {
						windowsRedirect(getReturnUrl(role, false, slug));
					})
					.catch(() => {
						setValueToLocalStorage(INVITATION_TOKEN, invitationToken);
						history.replace(loginRoute);
					});
			}
		} else {
			history.replace(loginRoute);
		}
	}, [history, invitationToken, isAcceptingInvitation, newAccount, role, slug, user]);

	return <LoadingPage />;
};

export default AcceptInvitation;
