import { createSlice } from '@reduxjs/toolkit';
import { getSuiteUserCookie } from '../../../../shared/application/helpers/common-functions';

const user = JSON.parse(getSuiteUserCookie());

export const initialState = user || {};

const User = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setCurrentUser(state, { payload }) {
			state.user = payload;
		},
	},
});

export const { setCurrentUser } = User.actions;

export default User.reducer;
