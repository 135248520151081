import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { removeUserSession } from '../../../../../shared/application/helpers/common-functions';
import { loginRoute } from '../../../infrastructure/routes';
import LoadingPage from '../../../../../shared/presentation/components/LoadingPage';

const LogoutPage = () => {
	const history = useHistory();
	useEffect(() => {
		removeUserSession();

		setTimeout(() => {
			history.push(loginRoute);
		}, 250);
	}, [history]);

	return <LoadingPage show={true} />;
};

export default LogoutPage;
