import React from 'react';
import { useTranslation } from 'react-i18next';
import { DOMAIN_NAME } from '../../infrastructure/locales';
import { TERMS } from '../../infrastructure/locales/translation_keys';

export const registerFields = {
	NAME: 'name',
	LAST_NAME: 'lastname',
	PHONE_NUMBER: 'mobile',
	EMAIL: 'email',
	ACCEPT_EMAILS_NOTIFICATIONS: 'receive_notification',
};

export const LOG_MESSAGE_ONE = () => {
	const { t } = useTranslation();

	return (
		<>
			{t(`${TERMS.ACCEPT}`, { ns: DOMAIN_NAME })}{' '}
			<a href="https://www.vecindariosuite.com/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">
				{t(`${TERMS.TEMS_AND_CONDITIONS}`, { ns: DOMAIN_NAME })}
			</a>{' '}
			{t(`${TERMS.AUTHORIZE}`, { ns: DOMAIN_NAME })}{' '}
			<a href="https://www.vecindariosuite.com/politica-de-tratamiento-de-datos" target="_blank" rel="noopener noreferrer">
				{t(`${TERMS.PRIVACY_POLICY}`, { ns: DOMAIN_NAME })}
			</a>
			.
		</>
	);
};
