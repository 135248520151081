export const LOGIN = Object.freeze({
	TITLE: 'loginTitle',
	TEXT_INFO: 'loginTextInfo',
	EMAIL: 'loginEmail',
	GOOGLE: 'loginGoogle',
	MICROSOFT: 'loginMicrosoft',
	ENTERPRISE: 'loginEnterprise',
});

export const FOOTER = Object.freeze({
	LOGIN_TEXT: 'loginText',
	LOGIN_LINK_TEXT: 'loginLinkText',
	SIGN_UP_TEXT: 'signUpText',
	SIGN_UP_LINK_TEXT: 'signUpLinkText',
});

export const SIGN_UP = Object.freeze({
	TITLE: `title`,
	TEXT_INFO: 'textInfo',
	EMAIL: 'signUpEmail',
	GOOGLE: 'signUpGoogle',
	MICROSOFT: 'signUpMicrosoft',
	ENTERPRISE: 'signUpEnterprise',
	TITLE_WELCOME: `titleWelcome`,
	TEXT_WELCOME: `textWelcome`,
	TRIAL_TEXT: `trialText`,
	ACCOUNT_ALREADY_EXIST: `accountAlreadyExist`,
});

export const TERMS = Object.freeze({
	ACCEPT: 'accept',
	TEMS_AND_CONDITIONS: 'termsAndConditions',
	AUTHORIZE: 'authorize',
	PRIVACY_POLICY: 'privacyPolicy',
});

export const OTP_VALIDATION = Object.freeze({
	TEXT: 'otpText',
	NO_EMAIL: 'noEmail',
	WRONG_OTP: 'wrongOtp',
	OTP_EXPIRED: 'otpExpired',
	ATTEMPS_ENOUGH: 'attempts',
});
