import React from 'react';

import './Divider.scss';

const Divider = () => (
	<div className="divider-container">
		<div className="line" />
	</div>
);

export default React.memo(Divider);
