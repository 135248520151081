import { createSelector } from '@reduxjs/toolkit';

export const socialLoginState = (state) => state.socialLogin;

export const showSocialLoginError = createSelector(socialLoginState, (_socialLoginState = {}) => {
	const { showError } = _socialLoginState;
	return showError;
});

export const getSocialLoginUser = createSelector(socialLoginState, (_socialLoginState = {}) => {
	const { user } = _socialLoginState;
	return user;
});
