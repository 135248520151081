import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { loginRoute } from '../../domains/authentication/infrastructure/routes';
import {
	ERROR_RETURN_URL,
	INTERMEDIATE_KEY,
	JWT_TOKEN,
	RETURN_URL,
	SSO_STATE,
	SUCCESS_RETURN_URL,
} from '../application/constants/queryParamsKeys';
import {
	TOKEN,
	RETURN_URL as LS_RETURN_URL,
	SSO_STATE as LS_SSO_STATE,
} from '../application/constants/localStorageKeys';
import {
	addParamToURL,
	getParamFromURL,
	getValueFromLocalStorage,
	locationToParams,
	removeParamFromUrl,
	removeValueFromLocalStorage,
	setValueToLocalStorage,
	windowsRedirect,
} from '../application/helpers/common-functions';
import { postNewJwtFromIntermediateToken } from '../infrastructure/api';
import LoadingPage from './components/LoadingPage';
import { errorRoute } from '../infrastructure/routing/routes';

const HomeRedirect = () => {
	const location = useLocation();
	const history = useHistory();

	const queryParams = locationToParams(location.search);
	const returnUrl = queryParams.get(RETURN_URL);
	const state = queryParams.get(SSO_STATE);
	const intermediate_token = getValueFromLocalStorage(TOKEN);

	const intermediate_key = getParamFromURL(returnUrl, INTERMEDIATE_KEY) || queryParams.get(INTERMEDIATE_KEY);
	const successReturnUrl = getParamFromURL(returnUrl, SUCCESS_RETURN_URL) || queryParams.get(SUCCESS_RETURN_URL);
	const errorReturnUrl = getParamFromURL(returnUrl, ERROR_RETURN_URL) || queryParams.get(ERROR_RETURN_URL);

	setValueToLocalStorage(LS_SSO_STATE, state);
	setValueToLocalStorage(LS_RETURN_URL, returnUrl);

	const getJwtToken = useCallback(async () => {
		try {
			const response = await postNewJwtFromIntermediateToken(intermediate_key || intermediate_token);
			setValueToLocalStorage(TOKEN, response?.intermediate_token);
			let urlToRedirect = addParamToURL(returnUrl, JWT_TOKEN, response?.jwt_token);
			urlToRedirect = addParamToURL(urlToRedirect, SUCCESS_RETURN_URL, successReturnUrl);
			urlToRedirect = addParamToURL(urlToRedirect, ERROR_RETURN_URL, errorReturnUrl);
			urlToRedirect = removeParamFromUrl(urlToRedirect, INTERMEDIATE_KEY);
			windowsRedirect(urlToRedirect);
		} catch (error) {
			if (errorReturnUrl) {
				setValueToLocalStorage(ERROR_RETURN_URL, errorReturnUrl);
				history.push(errorRoute);
			} else {
				removeValueFromLocalStorage(TOKEN);
				history.push(loginRoute);
			}
		}
	}, [errorReturnUrl, history, intermediate_key, intermediate_token, returnUrl, successReturnUrl]);

	useEffect(() => {
		if (!returnUrl || (!intermediate_token && !intermediate_key)) {
			history.push(loginRoute);
		} else {
			getJwtToken();
		}
	}, [getJwtToken, history, intermediate_key, intermediate_token, returnUrl]);

	return <LoadingPage show={true} />;
};

export default HomeRedirect;
