import { ROLES } from '../../../../shared/application/constants/application';

export const welcomeFields = {
	COMPANY: 'company',
	COMPANY_POSITION: 'company_position',
};

export const SELECT_OPTIONS = [
	{
		label: 'Asesor',
		value: ROLES.ADVISER,
	},
	{
		label: 'Director',
		value: ROLES.DIRECTOR,
	},
];

export const ACCOUNT_ALREADY_EXIST = 4010;
export const ERROR_CREATING_ACCOUNT = 4011;
export const EMAIL_NOT_ALLOWED = 4012;
