import { Helmet } from '@vecindario/vecindario-suite-components';
import React, { useEffect, useState } from 'react';
import { initializeTagManager } from './shared/infrastructure/gtm/index';
import { GTM_ID_SUITE, GTM_NO_SCRIPT_ID, GTM_SCRIPT_ID } from './shared/application/constants/env';
import Router from './shared/presentation/Router';
import '@vecindario/vecindario-suite-components/dist/index.css';

const App = () => {
	const [gtmInitialized, setGtmInitialized] = useState(false);

	useEffect(() => {
		let suiteScript;
		let suiteNoscript;

		if (!gtmInitialized) {
			setGtmInitialized(true);
			[suiteScript, suiteNoscript] = initializeTagManager(GTM_ID_SUITE, GTM_SCRIPT_ID, GTM_NO_SCRIPT_ID);
		}

		return () => {
			suiteScript && document.head.contains(suiteScript) && document.head.removeChild(suiteScript);
			suiteNoscript && document.body.contains(suiteNoscript) && document.body.removeChild(suiteNoscript);
		};
	}, [gtmInitialized]);

	return (
		<>
			<Helmet title={'Vecindario Suite - Iniciar sesión'} />
			<Router />
		</>
	);
};

export default App;
