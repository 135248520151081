import { createSelector } from '@reduxjs/toolkit';

export const applicationState = (state) => state.application;

export const getRegisterUserData = createSelector(applicationState, (_applicationState = {}) => {
	const { registerUserData } = _applicationState;
	return registerUserData;
});

export const getOtp = createSelector(applicationState, (_applicationState = {}) => {
	const { otp } = _applicationState;
	return otp;
});

export const getIntermediateToken = createSelector(applicationState, (application = {}) => {
	return application?.userCurrent?.intermediate_token;
});

export const getLoading = createSelector(applicationState, (application = {}) => {
	return application?.loading;
});

export const getError = createSelector(applicationState, (application = {}) => {
	return application?.error;
});
