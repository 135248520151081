import React from 'react';
import { Route, Switch } from 'react-router-dom';
import authRouter from '../../domains/authentication/infrastructure/router';
import { homeRoute } from '../infrastructure/routing/routes';
import HomeRedirect from './HomeRedirect';
import ErrorPage from './components/ErrorPage';

const Router = () => {
	const defaultLayout = ({ children }) => <>{children}</>;
	const routes = [authRouter];

	return (
		<Switch>
			<Route exact path={homeRoute}>
				<HomeRedirect />
			</Route>
			{routes.map((router) => {
				return router.router.map(({ path, page: Component, routeComponent: Route, exact = true, layout, ...rest }) => (
					<Route
						key="router"
						exact={exact}
						path={path}
						component={Component}
						layout={layout || router.layout || defaultLayout}
						{...rest}
					/>
				));
			})}
			<Route path="*">
				<ErrorPage />
			</Route>
		</Switch>
	);
};

export default Router;
