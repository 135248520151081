import {
	EMAIL_LABEL,
	EMAIL_ERROR,
	TRY_AGAIN,
	GO_BACK,
	SIGN_IN,
	SEND_PIN,
	SUBMIT_FORM,
	NAME_LABEL,
	LASTNAME_LABEL,
	CELLPHONE_LABEL,
	COMPANY_NAME_LABEL,
	JOB_TITLE_LABEL,
	WRONG_DATA_ERROR,
	CONTINUE_TEXT,
	CREATE_ACCOUNT_TEXT,
	SELECT_LANGUAGE_TEXT,
	EMPTY_FIELD,
	INVALID_EMAIL_FORMAT,
	USER_LABEL,
	PASSWORD_LABEL,
	INVALID_PHONE_NUMBER,
	ERROR_MESSAGE,
	TRY_AGAIN_TEXT,
} from '../translation_keys';

export default {
	[EMAIL_LABEL]: 'Correo electrónico',
	[EMAIL_ERROR]: 'El correo ingresado no tiene cuenta asociada',
	[TRY_AGAIN]: 'Ocurrió un error, intenta nuevamente',
	[GO_BACK]: 'Volver',
	[SIGN_IN]: 'Ingresar',
	[SEND_PIN]: 'Enviar pin',
	[SUBMIT_FORM]: 'Enviar',
	[NAME_LABEL]: 'Nombres',
	[LASTNAME_LABEL]: 'Apellidos',
	[CELLPHONE_LABEL]: 'Número de celular',
	[COMPANY_NAME_LABEL]: 'Nombre de la compañia',
	[JOB_TITLE_LABEL]: 'Tu cargo',
	[WRONG_DATA_ERROR]: 'Ha ocurrido un error, revisa los datos ingresados',
	[CONTINUE_TEXT]: 'Continuar',
	[CREATE_ACCOUNT_TEXT]: 'Crear cuenta',
	[SELECT_LANGUAGE_TEXT]: 'Selecciona el idioma',
	[EMPTY_FIELD]: 'Este campo no puede estar vacío',
	[INVALID_EMAIL_FORMAT]: 'Correo no válido. Intenta de nuevo',
	[USER_LABEL]: 'Usuario',
	[PASSWORD_LABEL]: 'Contraseña',
	[INVALID_PHONE_NUMBER]: 'Número no válido. Intenta de nuevo',
	[ERROR_MESSAGE]: 'Ha ocurrido un error',
	[TRY_AGAIN_TEXT]: 'Vuelve a intentarlo más tarde',
};
