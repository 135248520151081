import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
	Button,
	Input,
	TextTag,
	Helmet,
	useTranslate,
	LanguageCurrencySelector,
	i18nInstance,
} from '@vecindario/vecindario-suite-components';
import PhoneInput from '../../../../../shared/presentation/components/forms/phoneInput';

import { IMAGES_LAYOUT, TITLE_PIN_CODE_CHECK_IN } from '../../../../../shared/application/constants/application';
import {
	setRegisterUserData,
	setSectionImage,
	setTitlePinCode,
} from '../../../../../shared/application/slices/application';
import { registerSchema } from '../../../application/schemas/register';
import { LOG_MESSAGE_ONE, registerFields } from '../../../application/constants/register';
import { enterpriseLoginRoute, loginRoute, welcomeRoute } from '../../../infrastructure/routes';
import { getRegisterUserData } from '../../../../../shared/application/selectors/application';
import './Register.scss';
import Divider from '../../../../../shared/presentation/components/elements/divider';
import Footer from '../../../../../shared/presentation/components/elements/footer';
import { showSocialLoginError } from '../../../../socialLogin/application/selectors/socialLogin';
import SocialAuthButton from '../../../../socialLogin/presentation/components/socialAuthButton';
import { emailProviders } from '../../../../socialLogin/application/constants';
import { FOOTER, SIGN_UP } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import {
	CELLPHONE_LABEL,
	EMAIL_LABEL,
	GO_BACK,
	LASTNAME_LABEL,
	NAME_LABEL,
	SUBMIT_FORM,
	TRY_AGAIN,
} from '../../../../../shared/infrastructure/i18n/locales/translation_keys';

const RegisterPage = () => {
	const registerUserData = useSelector(getRegisterUserData);
	const dispatch = useDispatch();
	const history = useHistory();
	const [actualStep, setActualStep] = useState(1);
	const showLoginError = useSelector(showSocialLoginError);
	const {
		t,
		i18n: { language },
	} = useTranslation();

	const {
		register,
		formState: { errors, isDirty, isValid },
		handleSubmit,
		control,
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(registerSchema),
		defaultValues: registerUserData,
	});

	const onSubmit = (data) => {
		dispatch(setRegisterUserData(data));
		history.push(welcomeRoute);
	};

	useEffect(() => {
		dispatch(setSectionImage(IMAGES_LAYOUT.PAGE_REGISTER));
		dispatch(setTitlePinCode(TITLE_PIN_CODE_CHECK_IN));
	}, [dispatch]);

	const inputProps = (label, name) => {
		return {
			...register(name),
			showIconError: false,
			errorClassName: 'input-error-message',
			label,
			autoComplete: 'off',
		};
	};

	const stepOne = (
		<div className="form-container">
			<Button
				text={t(`${SIGN_UP.EMAIL}`, { ns: DOMAIN_NAME })}
				variant="default"
				className="email-register-btn"
				onClick={() => setActualStep(2)}
			/>

			<Divider />

			<SocialAuthButton
				provider={emailProviders.GOOGLE}
				className="register-btn-with-icon"
				text={t(`${SIGN_UP.GOOGLE}`, { ns: DOMAIN_NAME })}
			/>
			<SocialAuthButton
				provider={emailProviders.MICROSOFT}
				className="register-btn-with-icon"
				text={t(`${SIGN_UP.MICROSOFT}`, { ns: DOMAIN_NAME })}
			/>

			<Link to={enterpriseLoginRoute} className="go-to-enterprise">
				<TextTag tag="p" fw="normal" variant="-body-sm">
					{t(`${SIGN_UP.ENTERPRISE}`, { ns: DOMAIN_NAME })}
				</TextTag>
			</Link>

			<TextTag tag="p" fw="normal" variant="-body-sm" className="terms-and-conditions align-text-message">
				<LOG_MESSAGE_ONE />
			</TextTag>
			{showLoginError && (
				<TextTag tag="p" fw="normal" variant="-body-xs" className="social-register-error">
					{t(`${TRY_AGAIN}`, { ns: DOMAIN_NAME_SHARED })}
				</TextTag>
			)}
		</div>
	);

	const stepTwo = (
		<form className="form-group" onSubmit={handleSubmit(onSubmit)}>
			<Input
				error={useTranslate(errors[registerFields.NAME]?.message, DOMAIN_NAME_SHARED)}
				{...inputProps(t(`${NAME_LABEL}`, { ns: DOMAIN_NAME_SHARED }), registerFields.NAME)}
			/>
			<Input
				error={useTranslate(errors[registerFields.LAST_NAME]?.message, DOMAIN_NAME_SHARED)}
				{...inputProps(t(`${LASTNAME_LABEL}`, { ns: DOMAIN_NAME_SHARED }), registerFields.LAST_NAME)}
			/>
			<div className="wrap-inputs">
				<PhoneInput
					error={useTranslate(errors[registerFields.PHONE_NUMBER]?.message, DOMAIN_NAME_SHARED)}
					fieldName={registerFields.PHONE_NUMBER}
					control={control}
					label={t(`${CELLPHONE_LABEL}`, { ns: DOMAIN_NAME_SHARED })}
				/>
				<Input
					error={useTranslate(errors[registerFields.EMAIL]?.message, DOMAIN_NAME_SHARED)}
					{...inputProps(t(`${EMAIL_LABEL}`, { ns: DOMAIN_NAME_SHARED }), registerFields.EMAIL)}
				/>
			</div>
			<TextTag tag="p" font="Lato" variant="-body-xs" fw="normal" className="terms-and-conditions">
				<LOG_MESSAGE_ONE />
			</TextTag>
			<div className="container-buttons-register">
				<Button className="submit-btn" variant="default" disabled={!isValid || !isDirty}>
					{t(`${SUBMIT_FORM}`, { ns: DOMAIN_NAME_SHARED })}
				</Button>

				<div className="go-back" onClick={() => setActualStep(1)}>
					<TextTag tag="p" fw="normal" variant="-body-sm" font="DM-sans" className="text">
						{t(`${GO_BACK}`, { ns: DOMAIN_NAME_SHARED })}
					</TextTag>
				</div>
			</div>
		</form>
	);

	return (
		<>
			<Helmet title={'Vecindario Suite - Crear cuenta'} />
			<div className="register-page-container">
				<div className="register-page-wrapper">
					<div className="body">
						<LanguageCurrencySelector defaultLang={language} i18n={i18nInstance} position="left" />
						<TextTag tag="h4" className="title" fw="normal">
							{t(`${SIGN_UP.TITLE}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" fw="normal" variant="-body-sm" className="description">
							{t(`${SIGN_UP.TEXT_INFO}`, { ns: DOMAIN_NAME })}
						</TextTag>

						{actualStep === 1 ? stepOne : stepTwo}
					</div>
				</div>

				<Footer
					text={t(`${FOOTER.SIGN_UP_TEXT}`, { ns: DOMAIN_NAME })}
					linkText={t(`${FOOTER.SIGN_UP_LINK_TEXT}`, { ns: DOMAIN_NAME })}
					pushTo={loginRoute}
				/>
			</div>
		</>
	);
};

export default RegisterPage;
