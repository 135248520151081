import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@vecindario/vecindario-suite-components';
import { history as historyHelper } from '../../../../../shared/application/helpers/history';
import {
	createSuiteUserCookie,
	getCurrentPath,
	getReturnUrl,
	getValueFromLocalStorage,
	locationToParams,
	removeValueFromLocalStorage,
	setValueToLocalStorage,
	windowsRedirect,
} from '../../../../../shared/application/helpers/common-functions';
import {
	INVITATION_ROLE,
	SOCIAL_AUTH_PROVIDER,
	SOCIAL_LOGIN_ERROR,
	TOKEN,
	SOCIAL_AUTH_REGISTER,
} from '../../../../../shared/application/constants/localStorageKeys';
import { setUser, showError } from '../../../application/slices/socialLogin';
import {
	SOCIAL_AUTH_BACKEND_FUNCTION,
	SOCIAL_AUTH_LOGO,
	SOCIAL_AUTH_REDIRECT_URL,
	SOCIAL_AUTH_UUID_KEY,
} from '../../../application/constants/microsoft';

import { updateDataRoute } from '../../../../authentication/infrastructure/routes';
import { acceptProjectAccessInvitation } from '../../../../../shared/application/slices/application';
import { JWT_TOKEN } from '../../../../../shared/application/constants/queryParamsKeys';
import { emailProviders } from '../../../application/constants';

const SocialAuthButton = ({ className, provider, text }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const queryParams = locationToParams(historyHelper.location.search);
	const code = queryParams.get('code');
	const history = useHistory();
	const invitationRole = getValueFromLocalStorage(INVITATION_ROLE);

	const state = queryParams.get('state');
	const isLoading = useMemo(() => code && state, [code, state]);
	const postRequest = SOCIAL_AUTH_BACKEND_FUNCTION[provider];
	const redirectUrl = SOCIAL_AUTH_REDIRECT_URL[provider];
	const isSameProvider = useMemo(() => getValueFromLocalStorage(SOCIAL_AUTH_PROVIDER) === provider, [provider]);

	const handleLogin = () => {
		const uuid = uuidv4();
		setValueToLocalStorage(SOCIAL_AUTH_UUID_KEY[provider], uuid);
		setValueToLocalStorage(SOCIAL_AUTH_PROVIDER, provider);
		const url = redirectUrl({ uuid, currentPath: getCurrentPath() });
		windowsRedirect(url);
	};

	useEffect(() => {
		if (code && isSameProvider) {
			const body = {
				code,
				redirect_uri: getCurrentPath(),
				provider,
			};
			postRequest(body)
				.then(async (res) => {
					dispatch(showError(false));
					setValueToLocalStorage(SOCIAL_AUTH_REGISTER, res?.social_register);
					removeValueFromLocalStorage(SOCIAL_LOGIN_ERROR);
					removeValueFromLocalStorage(SOCIAL_AUTH_PROVIDER);
					setValueToLocalStorage(TOKEN, res?.intermediate_token?.intermediate_token);
					await acceptProjectAccessInvitation(res?.token);

					if ((provider === emailProviders.MICROSOFT || provider === emailProviders.GOOGLE) && res.social_register) {
						createSuiteUserCookie(JSON.stringify(res));

						dispatch(setUser(res));
						setTimeout(() => {
							history.push(updateDataRoute);
						}, 500);
					} else {
						windowsRedirect(
							`${getReturnUrl(
								invitationRole || res?.role,
								false,
								res?.slug_first_project,
								res?.modules_by_project,
								res?.disable_main_dashboard_access,
							)}?${JWT_TOKEN}=${res.token}`,
						);
					}
				})
				.catch(() => {
					dispatch(showError(true));
					setValueToLocalStorage(SOCIAL_LOGIN_ERROR, true);
				})
				.finally(() => {
					history.replace(location.pathname);
					removeValueFromLocalStorage(SOCIAL_AUTH_UUID_KEY[provider]);
				});
		}
	}, [dispatch, code, history, location.pathname, state, isSameProvider, postRequest, provider, invitationRole]);

	return (
		<Button variant="bordered" className={className} onClick={handleLogin} disabled={isLoading}>
			<img src={SOCIAL_AUTH_LOGO[provider]} className="logo" />
			{isLoading && isSameProvider ? 'Cargando...' : text}
		</Button>
	);
};

SocialAuthButton.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string.isRequired,
	provider: PropTypes.string.isRequired,
};

export default SocialAuthButton;
