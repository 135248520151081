import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { addInvitationTokenToParams } from '../../../socialLogin/application/helpers';
import {
	urlAcceptInvitation,
	urlEnterpriseLogin,
	urlLoginOtp,
	urlRegisterWithEmail,
	urlUpdateUserDetails,
} from './backendUrls';

export const postRegisterUser = (body) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body),
	};
	return fetch(urlRegisterWithEmail, requestOptions).then(handleResponse);
};

export const postLoginOtp = (body) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body),
	};
	return fetch(urlLoginOtp, requestOptions).then(handleResponse);
};

export const patchUpdateUserDetails = (body, userDetailId) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(body),
	};
	return fetch(urlUpdateUserDetails(userDetailId), requestOptions).then(handleResponse);
};

export const postEnterpriseLogin = (body) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(addInvitationTokenToParams(body)),
	};
	return fetch(urlEnterpriseLogin, requestOptions).then(handleResponse);
};

export const postAcceptInvitation = (invitation_token, token) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader('application/json', token),
		body: JSON.stringify({
			invitation_token,
		}),
	};
	return fetch(urlAcceptInvitation, requestOptions).then(handleResponse);
};
