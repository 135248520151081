import { getValueFromLocalStorage, isObject } from '../../../../shared/application/helpers/common-functions';
import { INVITATION_TOKEN } from '../../../../shared/application/constants/localStorageKeys';

export const addInvitationTokenToParams = (params) => {
	const invitationToken = getValueFromLocalStorage(INVITATION_TOKEN);
	if (invitationToken && isObject(params)) {
		params = { ...params, invitation_token: invitationToken };
	}
	return params;
};

export default {};
