import React from 'react';
import EnterpriseLogin from '../../components/login/EnterpriseLogin';
import LoginLayout from '../../components/login/LoginLayout';

const EnterpriseLoginPage = (props) => {
	return (
		<LoginLayout>
			<EnterpriseLogin />
		</LoginLayout>
	);
};

export default EnterpriseLoginPage;
