export const VECINDARIO_SUITE_LOGO = 'https://fiducia-suite.s3.us-east-2.amazonaws.com/vecindario-logo-light.svg';

export const IMAGES_LAYOUT = {
	PAGE_LOGIN: 'https://suite-frontend-sso.s3.us-east-2.amazonaws.com/side-img-login.png',
	PAGE_REGISTER: 'https://suite-frontend-sso.s3.us-east-2.amazonaws.com/side-img-sign-up.png',
	PAGE_WELCOME: 'https://suite-frontend-sso.s3.us-east-2.amazonaws.com/side-img-welcome.png',
};

export const TITLE_PIN_CODE_LOGIN = 'Inicia sesión';
export const TITLE_PIN_CODE_CHECK_IN = 'Crea tu cuenta';

export const ROLES = {
	ADVISER: 'adviser',
	DIRECTOR: 'director',
};

export default {};
