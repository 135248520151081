import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LanguageCurrencySelector, TextTag, i18nInstance } from '@vecindario/vecindario-suite-components';
import Footer from '../../../../../../shared/presentation/components/elements/footer';
import { registerRoute } from '../../../../infrastructure/routes';
import { LOGIN, FOOTER } from '../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';

const LoginLayout = ({ children }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();

	return (
		<div className="login-page-container">
			<div className="login-page-wrapper">
				<div className="body">
					<LanguageCurrencySelector defaultLang={language} i18n={i18nInstance} position="left" />
					<TextTag tag="h4" className="title" fw="normal">
						{t(`${LOGIN.TITLE}`, { ns: DOMAIN_NAME })}
					</TextTag>

					<TextTag tag="p" fw="normal" variant="-body-sm" className="description">
						{t(`${LOGIN.TEXT_INFO}`, { ns: DOMAIN_NAME })}
					</TextTag>
					{children}
				</div>
			</div>

			<Footer
				text={t(`${FOOTER.LOGIN_TEXT}`, { ns: DOMAIN_NAME })}
				linkText={t(`${FOOTER.LOGIN_LINK_TEXT}`, { ns: DOMAIN_NAME })}
				pushTo={registerRoute}
			/>
		</div>
	);
};

LoginLayout.propTypes = {
	children: PropTypes.node,
};

export default LoginLayout;
