// TEXTS
export const TRY_AGAIN = 'tryAgain';
export const GO_BACK = 'goBack';
export const SIGN_IN = 'signIn';
export const SEND_PIN = 'sendPin';
export const SUBMIT_FORM = 'submitForm';
export const CONTINUE_TEXT = 'continue';
export const CREATE_ACCOUNT_TEXT = 'createAccount';
export const SELECT_LANGUAGE_TEXT = 'selectLanguage';
export const ERROR_MESSAGE = 'errorMessage';
export const TRY_AGAIN_TEXT = 'tryAgainText';

// FORM LABELS
export const EMAIL_LABEL = 'email';
export const USER_LABEL = 'user';
export const PASSWORD_LABEL = 'password';
export const NAME_LABEL = 'name';
export const LASTNAME_LABEL = 'lastname';
export const CELLPHONE_LABEL = 'cellphone';
export const COMPANY_NAME_LABEL = 'companyName';
export const JOB_TITLE_LABEL = 'jobTitle';

// FORM ERRORS
export const EMPTY_FIELD = 'emptyField';
export const INVALID_EMAIL_FORMAT = 'invalidEmailFormat';
export const WRONG_DATA_ERROR = 'wrongDataError';
export const EMAIL_ERROR = 'wrongEmail';
export const INVALID_PHONE_NUMBER = 'invalidPhoneNumber';
