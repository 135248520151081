import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingPage from '../../components/LoadingPage';
import { VECINDARIO_SUITE_LOGO } from '../../../application/constants/application';

// Style
import './AdminLayout.scss';

const AdminLayout = ({ children }) => {
	const sectionImage = useSelector(({ application }) => application?.sectionImage);

	return (
		<div className="admin-layout-container">
			<LoadingPage />
			<div className="content-left">
				<div className="header">
					<div className="container-logo">
						<img className="logo" src={VECINDARIO_SUITE_LOGO} alt="logo" />
					</div>
				</div>
				<div className="child-container">{children}</div>
			</div>
			<div className="content-right">
				<img className="banner" src={sectionImage} alt="img" />
			</div>
		</div>
	);
};

AdminLayout.propTypes = {
	children: PropTypes.node,
};

AdminLayout.defaultProps = {
	children: null,
};
export default AdminLayout;
