import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, TextTag, useTranslate } from '@vecindario/vecindario-suite-components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	INVITATION_ROLE,
	LAST_SELECTED_SLUG,
	TOKEN,
} from '../../../../../../shared/application/constants/localStorageKeys';
import { JWT_TOKEN } from '../../../../../../shared/application/constants/queryParamsKeys';
import {
	createSuiteCookie,
	createSuiteUserCookie,
	getReturnUrl,
	getValueFromLocalStorage,
	setValueToLocalStorage,
	windowsRedirect,
} from '../../../../../../shared/application/helpers/common-functions';
import { acceptProjectAccessInvitation } from '../../../../../../shared/application/slices/application';
import { enterpriseLoginFields } from '../../../../application/constants/login';
import { enterpriseLoginSchema } from '../../../../application/schemas/enterpriseLogin';
import { postEnterpriseLogin } from '../../../../infrastructure/api';
import { updateDataRoute } from '../../../../infrastructure/routes';
import './EnterpriseLogin.scss';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../../shared/infrastructure/i18n/locales';
import {
	GO_BACK,
	PASSWORD_LABEL,
	SIGN_IN,
	USER_LABEL,
} from '../../../../../../shared/infrastructure/i18n/locales/translation_keys';

const EnterpriseLogin = () => {
	const history = useHistory();
	const invitationRole = getValueFromLocalStorage(INVITATION_ROLE);
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(enterpriseLoginSchema),
	});
	const [generalError, setGeneralError] = useState();

	const onSubmit = async (formData) => {
		try {
			setGeneralError(null);
			const user = await postEnterpriseLogin(formData);
			if (user?.token) {
				await acceptProjectAccessInvitation(user?.token);
			}
			setValueToLocalStorage(TOKEN, user?.intermediate_token);
			createSuiteUserCookie(JSON.stringify(user));
			if (user?.slug_first_project) {
				createSuiteCookie(LAST_SELECTED_SLUG, user?.slug_first_project);
			}
			if (user?.is_register) {
				history.push(updateDataRoute);
			} else {
				windowsRedirect(
					`${getReturnUrl(
						invitationRole,
						false,
						user?.slug_first_project,
						user?.modules_by_project,
						user?.disable_main_dashboard_access,
					)}?${JWT_TOKEN}=${user?.token}`,
				);
			}
		} catch (error) {
			const errorMsg = typeof error === 'string' ? error : error?.message;
			setGeneralError(errorMsg);
		}
	};

	return (
		<form className="enterprise-login form-group" onSubmit={handleSubmit(onSubmit)}>
			<Input
				label={t(`${USER_LABEL}`, { ns: DOMAIN_NAME_SHARED })}
				error={useTranslate(errors[enterpriseLoginFields.USERNAME]?.message, DOMAIN_NAME_SHARED)}
				autoComplete="off"
				{...register(enterpriseLoginFields.USERNAME)}
			/>

			<Input
				type="password"
				label={t(`${PASSWORD_LABEL}`, { ns: DOMAIN_NAME_SHARED })}
				error={useTranslate(errors[enterpriseLoginFields.PASSWORD]?.message, DOMAIN_NAME_SHARED)}
				autoComplete="off"
				{...register(enterpriseLoginFields.PASSWORD)}
			/>

			<Button
				text={t(`${SIGN_IN}`, { ns: DOMAIN_NAME_SHARED })}
				variant="default"
				className="email-login-btn"
				disabled={!isValid}
			/>

			<div className="go-back" onClick={() => history.goBack()}>
				<TextTag tag="p" fw="normal" variant="-body-sm" font="DM-sans" className="text">
					{t(`${GO_BACK}`, { ns: DOMAIN_NAME_SHARED })}
				</TextTag>
			</div>

			<TextTag tag="p" fw="normal" variant="-body-sm" className="general-error color-error">
				{generalError}
			</TextTag>
		</form>
	);
};

export default EnterpriseLogin;
