import { combineReducers } from 'redux';
import user from '../../../domains/authentication/application/slices/user';
import application from '../slices/application';
import socialLogin from '../../../domains/socialLogin/application/slices/socialLogin';

export default combineReducers({
	user,
	application,
	socialLogin,
});
