import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
	CodeVerification,
	Helmet,
	LanguageCurrencySelector,
	TextTag,
	i18nInstance,
} from '@vecindario/vecindario-suite-components';

import {
	postValidateOpt,
	setError,
	setLoading,
	setOtp,
	setSectionImage,
	updateOtp,
} from '../../../../../shared/application/slices/application';
import { IMAGES_LAYOUT } from '../../../../../shared/application/constants/application';
import { loginReceiveCodeRoute, loginRoute, registerRoute } from '../../../infrastructure/routes';
import { getError, getOtp } from '../../../../../shared/application/selectors/application';
import { postLoginOtp } from '../../../infrastructure/api';
import { JWT_TOKEN } from '../../../../../shared/application/constants/queryParamsKeys';
import {
	getReturnUrl,
	getValueFromLocalStorage,
	removeValueFromLocalStorage,
	setValueToLocalStorage,
	windowsRedirect,
} from '../../../../../shared/application/helpers/common-functions';
import { INVITATION_ROLE, OTP_VALUES } from '../../../../../shared/application/constants/localStorageKeys';
import { OPT_STORAGE_VALUES } from '../../../../../shared/application/constants/otp';
import './ReceiveCode.scss';
import { SECONDS_TO_SHOW_RESEND_MESSAGE } from '../../../application/constants/login';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { GO_BACK } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { LOGIN, OTP_VALIDATION } from '../../../infrastructure/locales/translation_keys';

const MAX_ATTEMPTS = 3;

const ReceiveCode = () => {
	const history = useHistory();
	const location = useLocation();
	const [showPinCode, setShowPinCode] = useState(false);
	const dispatch = useDispatch();
	const otpCurrent = useSelector(getOtp);
	const invitationRole = getValueFromLocalStorage(INVITATION_ROLE);
	const errors = useSelector(getError);
	const {
		t,
		i18n: { language },
	} = useTranslation();

	const isLoginPath = location.pathname === loginReceiveCodeRoute;
	const getExpiredTime = useMemo(() => {
		const expiredTime = Math.round((otpCurrent?.expiredTime - Date.now()) / 1000);
		return expiredTime > 0 ? expiredTime : 0;
	}, [otpCurrent]);

	useEffect(() => {
		if (!otpCurrent?.showIsExpired && getExpiredTime > 0) {
			setShowPinCode(true);
			!isLoginPath && dispatch(setSectionImage(IMAGES_LAYOUT.PAGE_REGISTER));
		} else {
			removeValueFromLocalStorage(OTP_VALUES);
		}
		if (Object.keys(otpCurrent).length === 0) history.push(isLoginPath ? loginRoute : registerRoute);
	}, [dispatch, history, isLoginPath, otpCurrent, getExpiredTime]);

	const onComplete = async (pinCode) => {
		const code = Object.values(pinCode).join('');
		if (otpCurrent?.id && otpCurrent?.attempts < MAX_ATTEMPTS && !otpCurrent?.showIsExpired) {
			const response = await dispatch(postValidateOpt({ id: otpCurrent.id, otp: code }));
			if (response?.payload?.error) {
				setShowPinCode(false);
				dispatch(updateOtp({ attempts: otpCurrent?.attempts + 1, showIsExpired: false, showError: true }));
				setShowPinCode(true);
				setTimeout(() => dispatch(setLoading(false)), 500);
			} else {
				removeValueFromLocalStorage(OTP_VALUES);
				windowsRedirect(
					`${getReturnUrl(
						invitationRole || response?.payload?.role,
						!isLoginPath,
						response?.payload?.slug_first_project,
						response?.payload?.modules_by_project,
						response?.payload?.disable_main_dashboard_access,
					)}?${JWT_TOKEN}=${response?.payload?.jwt_token}`,
				);
			}
		}
	};

	const onResend = () => {
		if (otpCurrent?.attempts <= MAX_ATTEMPTS) {
			dispatch(setLoading(true));
			setShowPinCode(false);
			const otpBody = { method: 'email', value: otpCurrent.email };
			postLoginOtp(otpBody)
				.then((res) => {
					dispatch(setError(null));
					const body = {
						...res,
						email: otpCurrent.email,
						expiredTime: Date.now() + res.expires_in * 1000,
						resendCodeTime: res.expires_in - SECONDS_TO_SHOW_RESEND_MESSAGE,
						...OPT_STORAGE_VALUES,
					};
					setValueToLocalStorage(OTP_VALUES, JSON.stringify(body));
					dispatch(setOtp(body));
				})
				.catch((err) => {
					setShowPinCode(true);
					dispatch(setError(err.error));
					dispatch(setOtp({ ...otpCurrent, showError: false }));
				})
				.finally(() => setTimeout(() => dispatch(setLoading(false)), 500));
		}
	};

	const onTimeOut = () => {
		dispatch(setError(null));
		!otpCurrent?.showIsExpired && dispatch(updateOtp({ showIsExpired: true, showError: false }));
	};

	const renderErrors = () => {
		if (otpCurrent?.showIsExpired) {
			return t(`${OTP_VALIDATION.OTP_EXPIRED}`, { ns: DOMAIN_NAME });
		}
		if (otpCurrent?.showError) {
			return otpCurrent.attempts === MAX_ATTEMPTS
				? t(`${OTP_VALIDATION.ATTEMPS_ENOUGH}`, { ns: DOMAIN_NAME })
				: t(`${OTP_VALIDATION.WRONG_OTP}`, { ns: DOMAIN_NAME });
		}
		if (errors) {
			return errors;
		}
		return null;
	};

	return (
		<>
			<Helmet title={'Vecindario Suite - Iniciar sesión - Recibir código'} />
			<div className="receive-code-page-container">
				<div className="receive-code-wrapper">
					<div className="container">
						{showPinCode && (
							<>
								<LanguageCurrencySelector defaultLang={language} i18n={i18nInstance} position="left" />
								<CodeVerification
									title={t(`${LOGIN.TITLE}`, { ns: DOMAIN_NAME })}
									titleClassName="title"
									description={
										<>
											<TextTag tag="p" font="DM-sans" fw="normal" variant="-body-sm" className="description">
												{t(`${OTP_VALIDATION.TEXT}`, { ns: DOMAIN_NAME })} <b>{otpCurrent?.email}</b>
											</TextTag>
										</>
									}
									error={renderErrors()}
									expiryTime={getExpiredTime}
									resendCodeTime={otpCurrent.resendCodeTime}
									pinCodeProps={{
										size: 'large',
										inputStyle: { color: '#0b1d2e', fontWeight: 'bold' },
										digits: 6,
										onComplete,
									}}
									onTimeOuts={onTimeOut}
									onResend={onResend}
								/>
							</>
						)}

						<p className="go-back">
							<TextTag tag="p" font="DM-sans" variant="-body-sm" fw="normal" className="text">
								{t(`${OTP_VALIDATION.NO_EMAIL}`, { ns: DOMAIN_NAME })}{' '}
								<a className="link" onClick={() => history.goBack()}>
									{t(`${GO_BACK}`, { ns: DOMAIN_NAME_SHARED })}
								</a>
							</TextTag>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default ReceiveCode;
