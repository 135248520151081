import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@vecindario/vecindario-suite-components';
import { useSelector } from 'react-redux';
import { getLoading } from '../../../application/selectors/application';
import './LoadingPage.scss';

const LoadingPage = ({ onPage, show }) => {
	const isLoading = useSelector(getLoading);

	return isLoading || show ? (
		<div className={`loading-full-page ${onPage ? 'with-overlay' : ''}`}>
			<Spinner size="large" />
		</div>
	) : (
		<></>
	);
};

LoadingPage.propTypes = {
	onPage: PropTypes.bool,
	show: PropTypes.bool,
};

LoadingPage.defaultProps = {
	onPage: true,
	show: false,
};

export default LoadingPage;
