import { authHeader, handleResponse } from './apiHandler';
import {
	urlPostCreateAccount,
	urlPostGenerateOpt,
	urlPostNewJwtFromIntermediateToken,
	urlPostValidateOpt,
} from './backendUrls';

export const postRequestCreateAccount = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};

	return fetch(urlPostCreateAccount, requestOptions).then(handleResponse);
};

export const postRequestGenerateOpt = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};

	return fetch(urlPostGenerateOpt, requestOptions).then(handleResponse);
};

export const postRequestValidateOpt = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};

	return fetch(urlPostValidateOpt, requestOptions).then(handleResponse);
};

export const postNewJwtFromIntermediateToken = (token) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({ token }),
	};

	return fetch(urlPostNewJwtFromIntermediateToken, requestOptions).then(handleResponse);
};
