import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Helmet,
	Input,
	LanguageCurrencySelector,
	TextTag,
	i18nInstance,
	useTranslate,
} from '@vecindario/vecindario-suite-components';

import { welcomeSchema } from '../../../application/schemas/welcome';
import {
	welcomeFields,
	ACCOUNT_ALREADY_EXIST,
	ERROR_CREATING_ACCOUNT,
	EMAIL_NOT_ALLOWED,
} from '../../../application/constants/welcome';
import { setOtp, setSectionImage } from '../../../../../shared/application/slices/application';
import { IMAGES_LAYOUT } from '../../../../../shared/application/constants/application';
import { getRegisterUserData } from '../../../../../shared/application/selectors/application';
import { loginRoute, receiveCodeRoute, registerRoute } from '../../../infrastructure/routes';
import { patchUpdateUserDetails, postLoginOtp, postRegisterUser } from '../../../infrastructure/api';
import './Welcome.scss';
import Footer from '../../../../../shared/presentation/components/elements/footer';
import {
	getReturnUrl,
	getSuiteUserCookie,
	getValueFromLocalStorage,
	removeValueFromLocalStorage,
	setValueToLocalStorage,
	windowsRedirect,
} from '../../../../../shared/application/helpers/common-functions';
import {
	INVITATION_ROLE,
	OTP_VALUES,
	SOCIAL_AUTH_REGISTER,
} from '../../../../../shared/application/constants/localStorageKeys';
import { OPT_STORAGE_VALUES } from '../../../../../shared/application/constants/otp';
import { JWT_TOKEN } from '../../../../../shared/application/constants/queryParamsKeys';
import { SECONDS_TO_SHOW_RESEND_MESSAGE } from '../../../application/constants/login';
import { FOOTER, SIGN_UP } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import {
	COMPANY_NAME_LABEL,
	CONTINUE_TEXT,
	CREATE_ACCOUNT_TEXT,
	JOB_TITLE_LABEL,
	WRONG_DATA_ERROR,
} from '../../../../../shared/infrastructure/i18n/locales/translation_keys';

const Welcome = () => {
	const history = useHistory();
	const registerUserData = useSelector(getRegisterUserData);
	const [showError, setShowError] = useState(false);
	const dispatch = useDispatch();
	const user = JSON.parse(getSuiteUserCookie());
	const invitationRole = getValueFromLocalStorage(INVITATION_ROLE);
	const {
		t,
		i18n: { language },
	} = useTranslation();

	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(welcomeSchema),
	});

	const onSubmit = (data) => {
		const body = { ...data, ...registerUserData };
		setShowError(false);
		if (user) {
			patchUpdateUserDetails(data, user.user_detail.id).then(() => {
				removeValueFromLocalStorage(SOCIAL_AUTH_REGISTER);
				windowsRedirect(`${getReturnUrl(invitationRole, true)}?${JWT_TOKEN}=${user.token}`);
			});
		} else {
			postRegisterUser(body)
				.then(() => {
					removeValueFromLocalStorage(INVITATION_ROLE);
					const otpBody = { method: 'email', value: body.email };
					postLoginOtp(otpBody).then((res) => {
						const loginBody = {
							...res,
							email: body.email,
							expiredTime: Date.now() + res.expires_in * 1000,
							resendCodeTime: res.expires_in - SECONDS_TO_SHOW_RESEND_MESSAGE,
							...OPT_STORAGE_VALUES,
						};
						setValueToLocalStorage(OTP_VALUES, JSON.stringify(loginBody));
						dispatch(setOtp(loginBody));
						history.push(receiveCodeRoute);
					});
				})
				.catch((e) => {
					setShowError(e);
				});
		}
	};

	const inputProps = (label, name) => {
		return {
			...register(name),
			showIconError: false,
			errorClassName: 'input-error-message',
			label,
			autoComplete: 'off',
		};
	};

	useEffect(() => {
		if (!user && Object.keys(registerUserData).length === 0) history.push(registerRoute);
		dispatch(setSectionImage(IMAGES_LAYOUT.PAGE_WELCOME));
	}, [dispatch, history, registerUserData, user]);

	const showFreeDays = () => {
		if (invitationRole) return false;
		return true;
	};

	return (
		<>
			<Helmet title={'Vecindario Suite - Crear cuenta - Completar perfil'} />
			<div className="welcome-page-container">
				<div className="welcome-page-wrapper">
					<div className="container">
						<LanguageCurrencySelector defaultLang={language} i18n={i18nInstance} position="left" />
						<TextTag tag="h4" fw="normal">
							{t(`${SIGN_UP.TITLE_WELCOME}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" fw="normal" variant="-body-sm" className="description">
							{t(`${SIGN_UP.TEXT_WELCOME}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<form className="form-group" onSubmit={handleSubmit(onSubmit)}>
							<Input
								error={useTranslate(errors[welcomeFields.COMPANY]?.message, DOMAIN_NAME_SHARED)}
								{...inputProps(t(`${COMPANY_NAME_LABEL}`, { ns: DOMAIN_NAME_SHARED }), welcomeFields.COMPANY)}
							/>
							<Input
								error={useTranslate(errors[welcomeFields.COMPANY_POSITION]?.message, DOMAIN_NAME_SHARED)}
								{...inputProps(t(`${JOB_TITLE_LABEL}`, { ns: DOMAIN_NAME_SHARED }), welcomeFields.COMPANY_POSITION)}
							/>

							{showFreeDays() && (
								<div className="promotion-message">
									<TextTag tag="p" fw="normal" variant="-body-sm" font="DM-sans" className="message">
										<p className="icon">🚀</p> {t(`${SIGN_UP.TRIAL_TEXT}`, { ns: DOMAIN_NAME })}{' '}
									</TextTag>
								</div>
							)}

							{showError?.code === ACCOUNT_ALREADY_EXIST && (
								<TextTag tag="p" font="DM-sans" fw="normal" variant="-body-sm" className="account-message">
									<p dangerouslySetInnerHTML={{ __html: t(`${SIGN_UP.ACCOUNT_ALREADY_EXIST}`, { ns: DOMAIN_NAME }) }}></p>
								</TextTag>
							)}

							{showError?.code === EMAIL_NOT_ALLOWED ||
								(showError?.code === ERROR_CREATING_ACCOUNT && (
									<TextTag tag="p" font="DM-sans" fw="normal" variant="-body-sm" className="error-message">
										{t(`${WRONG_DATA_ERROR}`, { ns: DOMAIN_NAME_SHARED })}
									</TextTag>
								))}

							<Button className="submit-btn" variant="default" disabled={!isValid}>
								{Object.keys(registerUserData).length > 0
									? t(`${CREATE_ACCOUNT_TEXT}`, { ns: DOMAIN_NAME_SHARED })
									: t(`${CONTINUE_TEXT}`, { ns: DOMAIN_NAME_SHARED })}
							</Button>
						</form>
					</div>
				</div>

				{showError?.code && (
					<Footer
						text={t(`${FOOTER.LOGIN_TEXT}`, { ns: DOMAIN_NAME })}
						linkText={t(`${FOOTER.LOGIN_LINK_TEXT}`, { ns: DOMAIN_NAME })}
						pushTo={loginRoute}
					/>
				)}
			</div>
		</>
	);
};

export default Welcome;
