import { LOGIN, FOOTER, SIGN_UP, TERMS, OTP_VALIDATION } from '../translation_keys';

export default {
	[LOGIN.TITLE]: `Inicia sesión`,
	[LOGIN.TEXT_INFO]: `Conéctate con la plataforma Vecindario Suite para empezar a gestionar tus negocios, clientes y aliados`,
	[LOGIN.EMAIL]: `Ingresa con correo electrónico`,
	[LOGIN.GOOGLE]: `Ingresa con Google`,
	[LOGIN.MICROSOFT]: `Ingresa con Microsoft`,
	[LOGIN.ENTERPRISE]: `Inicio de sesión empresarial`,
	[FOOTER.LOGIN_TEXT]: `¿No tienes cuenta?`,
	[FOOTER.LOGIN_LINK_TEXT]: `Regístrate`,
	[FOOTER.SIGN_UP_TEXT]: `¿Ya tienes cuenta?`,
	[FOOTER.SIGN_UP_LINK_TEXT]: `Entra aquí`,
	[SIGN_UP.TITLE]: `Crea tu cuenta`,
	[SIGN_UP.TEXT_INFO]: `Ingresa tus datos para crear tu cuenta`,
	[SIGN_UP.EMAIL]: `Regístrate con tu correo electrónico`,
	[SIGN_UP.GOOGLE]: `Regístrate con Google`,
	[SIGN_UP.MICROSOFT]: `Regístrate con Microsoft`,
	[SIGN_UP.ENTERPRISE]: `Cuenta empresarial`,
	[SIGN_UP.TITLE_WELCOME]: `¡Te damos la bienvenida a Vecindario Suite!`,
	[SIGN_UP.TEXT_WELCOME]: `Para continuar, necesitamos la información sobre la constructora donde trabajas y el cargo que desempeñas en ella`,
	[SIGN_UP.TRIAL_TEXT]: `¡30 días gratuito! Después podrás elegir el plan que más te guste`,
	[SIGN_UP.ACCOUNT_ALREADY_EXIST]: `No se puede crear la cuenta, es posible que ya exista una cuenta con ese correo. Intenta iniciando sesión <a href="/iniciar-sesion">aquí</a>.`,
	[TERMS.ACCEPT]: `Al continuar acepto los`,
	[TERMS.TEMS_AND_CONDITIONS]: `Términos y condiciones`,
	[TERMS.AUTHORIZE]: `y autorizo el`,
	[TERMS.PRIVACY_POLICY]: `Tratamiento de mis Datos Personales`,
	[OTP_VALIDATION.TEXT]: `Te enviamos un código a tu correo:`,
	[OTP_VALIDATION.NO_EMAIL]: `¿No es tu correo?`,
	[OTP_VALIDATION.WRONG_OTP]: `Algo salió mal, verifica el código ingresado`,
	[OTP_VALIDATION.OTP_EXPIRED]: `El código expiró, solicita uno nuevo`,
	[OTP_VALIDATION.ATTEMPS_ENOUGH]: 'Superaste el número de intentos, solicita un nuevo código de acceso',
};
