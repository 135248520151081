import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextTag } from '@vecindario/vecindario-suite-components';

import './Footer.scss';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../domains/socialLogin/application/slices/socialLogin';

const Footer = ({ text, pushTo, linkText }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const onClick = () => {
		dispatch(showError(false));
		history.push(pushTo);
	};

	return (
		<div className="footer-container">
			<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="normal" className="text">
				{text}
				<a onClick={onClick} className="link">
					{linkText}
				</a>
			</TextTag>
		</div>
	);
};

Footer.propTypes = {
	text: PropTypes.string.isRequired,
	pushTo: PropTypes.string,
	linkText: PropTypes.string,
};

export default Footer;
