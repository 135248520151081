import { createSlice } from '@reduxjs/toolkit';
import { getSuiteUserCookie } from '../../../../shared/application/helpers/common-functions';

const user = JSON.parse(getSuiteUserCookie());

export const initialState = {
	showError: false,
	user,
};

const SocialLogin = createSlice({
	name: 'socialLogin',
	initialState,
	reducers: {
		showError: (state, { payload }) => {
			state.showError = payload;
		},
		setUser: (state, { payload }) => {
			state.user = payload;
		},
	},
});

export const { showError, setUser } = SocialLogin.actions;

export default SocialLogin.reducer;
