import React from 'react';
import { Redirect } from 'react-router-dom';

import { loginRoute } from '../../../../domains/authentication/infrastructure/routes';

const StartRedirect = () => {
	return <Redirect to={loginRoute} />;
};

export default StartRedirect;
