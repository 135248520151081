import { helpers, moduleLinks, moduleKeys, language } from '@vecindario/suite-library';
import { PROD_ENV } from '../constants/env';
import {
	INVITATION_PROJECT_SLUG,
	INVITATION_ROLE,
	RETURN_URL,
	LAST_SELECTED_SLUG,
} from '../constants/localStorageKeys';
import { ADVISER_ROLE, DIRECTOR_ROLE, MARKETING_ROLE } from '../constants/roles';

export const {
	isObject,
	getValueFromLocalStorage,
	importResource,
	locationToParams,
	removeValueFromLocalStorage,
	setValueToLocalStorage,
	deleteCookie,
	removeQuery,
	getCurrerntPath,
	getSuiteUserCookie,
	createSuiteUserCookie,
	deleteSuiteUserCookie,
	deleteSuiteCookie,
	createSuiteCookie,
	checkCookieExistence,
	importDynamic,
	getParamFromURL,
	addParamToURL,
	removeParamFromUrl,
	removeUserSession,
} = helpers.commonFunctions;

export const windowsRedirect = (url) => {
	window.location.href = url;
};

export const getCurrentPath = getCurrerntPath;

export const getReturnUrl = (
	role = '',
	isRegister = false,
	slugFirstProject = false,
	modulesByProject = [],
	disable_main_dashboard_access = false,
) => {
	role = getValueFromLocalStorage(INVITATION_ROLE) || role;
	const invitationProjectSlug = getValueFromLocalStorage(INVITATION_PROJECT_SLUG);
	const accessToBuyerNotifications = modulesByProject && modulesByProject.includes('notificaciones_a_compradores');

	let slug = slugFirstProject;

	if (slug && !invitationProjectSlug) {
		createSuiteCookie(LAST_SELECTED_SLUG, slug);
	}

	if (invitationProjectSlug) {
		slug = invitationProjectSlug;
		createSuiteCookie(LAST_SELECTED_SLUG, slug);
		removeValueFromLocalStorage(INVITATION_PROJECT_SLUG);
		removeValueFromLocalStorage(INVITATION_ROLE);
	}

	if (!slug) {
		slug = checkCookieExistence(LAST_SELECTED_SLUG);
	}

	if (disable_main_dashboard_access) {
		return `${moduleLinks[moduleKeys.SCHEDULER_KEY]?.[PROD_ENV]}`;
	}

	switch (role) {
		case DIRECTOR_ROLE:
			return `${moduleLinks[moduleKeys.MAIN_DASHBOARD_KEY]?.[PROD_ENV]}${slug ? `proyecto/${slug}/tablero` : ''}`;
		case ADVISER_ROLE:
			return `${moduleLinks[moduleKeys.COMMERCIAL_OPPORTUNITIES_KEY]?.[PROD_ENV]}${
				slug ? `proyecto/${slug}/tablero` : ''
			}`;
		case MARKETING_ROLE:
			if (accessToBuyerNotifications) {
				return `${moduleLinks[moduleKeys.BUYER_NOTIFICATIONS_KEY]?.[PROD_ENV]}${
					slug ? `proyecto/${slug}/publicaciones` : ''
				}`;
			}
			return `${moduleLinks[moduleKeys.LANDING_BUILDER_KEY]?.[PROD_ENV]}${slug ? `proyecto/${slug}/landing` : ''}`;
		default:
			if (isRegister) {
				return `${moduleLinks[moduleKeys.MAIN_DASHBOARD_KEY]?.[PROD_ENV]}bienvenido/crear-proyecto`;
			}
			return (
				getValueFromLocalStorage(RETURN_URL) ||
				`${moduleLinks[moduleKeys.MAIN_DASHBOARD_KEY]?.[PROD_ENV]}${
					slug && slugFirstProject ? `proyecto/${slug}/tablero` : 'bienvenido/crear-proyecto'
				}`
			);
	}
};

export const { LOCALES, getConfigI18n, getNavigatorLanguage } = language;
