import * as yup from 'yup';
import { enterpriseLoginFields } from '../constants/login';
import { EMPTY_FIELD } from '../../../../shared/infrastructure/i18n/locales/translation_keys';

export const enterpriseLoginValidation = {
	[enterpriseLoginFields.USERNAME]: yup.string().required(EMPTY_FIELD),
	[enterpriseLoginFields.PASSWORD]: yup.string().required(EMPTY_FIELD),
};

export const enterpriseLoginSchema = yup.object().shape(enterpriseLoginValidation);
