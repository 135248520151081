import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';
import { Button, Helmet, Input, TextTag, useTranslate } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';

import { loginSchema } from '../../../application/schemas/login';
import { loginFields, SECONDS_TO_SHOW_RESEND_MESSAGE } from '../../../application/constants/login';
import { enterpriseLoginRoute, loginReceiveCodeRoute } from '../../../infrastructure/routes';
import './Login.scss';
import { postLoginOtp } from '../../../infrastructure/api';
import { setOtp, setSectionImage } from '../../../../../shared/application/slices/application';
import { IMAGES_LAYOUT } from '../../../../../shared/application/constants/application';
import Divider from '../../../../../shared/presentation/components/elements/divider';
import { setValueToLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { OTP_VALUES } from '../../../../../shared/application/constants/localStorageKeys';
import { OPT_STORAGE_VALUES } from '../../../../../shared/application/constants/otp';
import { showSocialLoginError } from '../../../../socialLogin/application/selectors/socialLogin';
import SocialAuthButton from '../../../../socialLogin/presentation/components/socialAuthButton';
import { emailProviders } from '../../../../socialLogin/application/constants';
import { showError as showSocialError } from '../../../../socialLogin/application/slices/socialLogin';
import LoginLayout from '../../components/login/LoginLayout';
import { LOGIN } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import {
	EMAIL_LABEL,
	TRY_AGAIN,
	GO_BACK,
	SEND_PIN,
	EMAIL_ERROR,
} from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { LOG_MESSAGE_ONE } from '../../../application/constants/register';

const Login = () => {
	const [showError, setShowError] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const [actualStep, setActualStep] = useState(1);
	const showLoginError = useSelector(showSocialLoginError);
	const { t } = useTranslation();

	const {
		register,
		formState: { errors, isDirty, isValid },
		watch,
		handleSubmit,
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(loginSchema),
	});

	const changeStep = (step) => {
		setActualStep(step);
		dispatch(showSocialError(false));
	};

	const stepOne = (
		<div className="form-container">
			<Button
				text={t(`${LOGIN.EMAIL}`, { ns: DOMAIN_NAME })}
				variant="default"
				className="email-login-btn"
				onClick={() => changeStep(2)}
			/>

			<Divider />

			<SocialAuthButton
				provider={emailProviders.GOOGLE}
				className="login-btn-with-icon"
				text={t(`${LOGIN.GOOGLE}`, { ns: DOMAIN_NAME })}
			/>
			<SocialAuthButton
				provider={emailProviders.MICROSOFT}
				className="login-btn-with-icon"
				text={t(`${LOGIN.MICROSOFT}`, { ns: DOMAIN_NAME })}
			/>

			<Link to={enterpriseLoginRoute} className="go-to-enterprise">
				<TextTag tag="p" fw="normal" variant="-body-sm">
					{t(`${LOGIN.ENTERPRISE}`, { ns: DOMAIN_NAME })}
				</TextTag>
			</Link>

			<TextTag tag="p" fw="normal" variant="-body-sm" className="terms-and-conditions align-text-message">
				<LOG_MESSAGE_ONE />
			</TextTag>

			{showLoginError && (
				<TextTag tag="p" fw="normal" variant="-body-xs" className="social-login-error">
					{t(`${TRY_AGAIN}`, { ns: DOMAIN_NAME_SHARED })}
				</TextTag>
			)}
		</div>
	);

	const onSubmit = (data) => {
		const otpBody = { method: 'email', value: data.email };
		postLoginOtp(otpBody)
			.then((res) => {
				const body = {
					...res,
					email: data.email,
					expiredTime: Date.now() + res.expires_in * 1000,
					resendCodeTime: res.expires_in - SECONDS_TO_SHOW_RESEND_MESSAGE,
					...OPT_STORAGE_VALUES,
				};
				setValueToLocalStorage(OTP_VALUES, JSON.stringify(body));
				dispatch(setOtp(body));
				history.push(loginReceiveCodeRoute);
			})
			.catch(() => {
				setShowError(true);
			});
	};

	const goBack = () => {
		setActualStep(1);
		setShowError(false);
		dispatch(showSocialError(false));
	};

	const currentEmailValue = watch(loginFields.EMAIL);

	useEffect(() => {
		currentEmailValue && setShowError(false);
	}, [currentEmailValue, setShowError]);

	const stepTwo = (
		<form className="form-group" onSubmit={handleSubmit(onSubmit)}>
			<Input
				label={t(`${EMAIL_LABEL}`, { ns: DOMAIN_NAME_SHARED })}
				error={
					useTranslate(errors[loginFields.EMAIL]?.message, DOMAIN_NAME_SHARED) ||
					(showError ? t(`${EMAIL_ERROR}`, { ns: DOMAIN_NAME_SHARED }) : '')
				}
				showIconError={false}
				autoComplete="off"
				errorClassName="input-error-message"
				{...register(loginFields.EMAIL)}
				className="email-input"
			/>

			<Button
				text={t(`${SEND_PIN}`, { ns: DOMAIN_NAME_SHARED })}
				variant="default"
				className="email-login-btn"
				disabled={!isValid || !isDirty}
			/>

			<br />

			<TextTag tag="p" fw="normal" variant="-body-sm" className="terms-and-conditions">
				<LOG_MESSAGE_ONE />
			</TextTag>

			<div className="go-back" onClick={goBack}>
				<TextTag tag="p" fw="normal" variant="-body-sm" font="DM-sans" className="text">
					{t(`${GO_BACK}`, { ns: DOMAIN_NAME_SHARED })}
				</TextTag>
			</div>
		</form>
	);

	useEffect(() => {
		dispatch(setSectionImage(IMAGES_LAYOUT.PAGE_LOGIN));
	}, [dispatch]);

	return (
		<>
			<Helmet title={'Vecindario Suite - Iniciar sesión'} />
			<LoginLayout>{actualStep === 1 ? stepOne : stepTwo}</LoginLayout>
		</>
	);
};

export default Login;
