import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postRequestCreateAccount, postRequestGenerateOpt, postRequestValidateOpt } from '../../infrastructure/api';
import { IMAGES_LAYOUT, TITLE_PIN_CODE_LOGIN } from '../constants/application';
import { INVITATION_TOKEN, LAST_SELECTED_SLUG, OTP_VALUES, TOKEN } from '../constants/localStorageKeys';
import {
	createSuiteCookie,
	getValueFromLocalStorage,
	removeValueFromLocalStorage,
	setValueToLocalStorage,
} from '../helpers/common-functions';
import { postAcceptInvitation } from '../../../domains/authentication/infrastructure/api';

export const initialState = {
	sectionImage: IMAGES_LAYOUT.PAGE_LOGIN,
	titlePinCode: TITLE_PIN_CODE_LOGIN,
	loading: false,
	error: null,
	otp: JSON.parse(getValueFromLocalStorage(OTP_VALUES)) || {},
	registerUserData: {},
};

export const acceptProjectAccessInvitation = async (token = '') => {
	const invitationToken = getValueFromLocalStorage(INVITATION_TOKEN);

	if (invitationToken) {
		await postAcceptInvitation(invitationToken, token);
		removeValueFromLocalStorage(INVITATION_TOKEN);
	}
};

export const postCreateAccount = createAsyncThunk(
	'application/postCreateAccount',
	async (data, { rejectWithValue }) => {
		try {
			await postRequestCreateAccount(data);
			return await postRequestGenerateOpt({
				value: data?.email,
				method: 'email',
			});
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const postValidateOpt = createAsyncThunk('application/postValidateOpt', async (data, { rejectWithValue }) => {
	try {
		const response = await postRequestValidateOpt(data);
		setValueToLocalStorage(TOKEN, response?.intermediate_token);
		if (response?.slug_first_project) {
			createSuiteCookie(LAST_SELECTED_SLUG, response?.slug_first_project);
		}
		await acceptProjectAccessInvitation(response?.jwt_token);

		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

const Application = createSlice({
	name: 'application',
	initialState,
	reducers: {
		setSectionImage: (state, { payload }) => {
			state.sectionImage = payload;
		},
		setTitlePinCode: (state, { payload }) => {
			state.titlePinCode = payload;
		},
		setRegisterUserData: (state, { payload }) => {
			state.registerUserData = payload;
		},
		setOtp: (state, { payload }) => {
			state.otp = payload;
		},
		updateOtp: (state, { payload }) => {
			state.otp = { ...state.otp, ...payload };
			setValueToLocalStorage(OTP_VALUES, JSON.stringify({ ...state.otp, ...payload }));
		},
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setError: (state, { payload }) => {
			state.error = payload;
		},
	},
	extraReducers: {
		[postCreateAccount.pending]: (state) => {
			state.error = null;
			state.loading = true;
		},
		[postCreateAccount.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = true;
		},
		[postCreateAccount.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.otp = payload;
		},
		[postValidateOpt.pending]: (state) => {
			state.error = null;
			state.loading = true;
		},
		[postValidateOpt.rejected]: (state) => {
			state.loading = true;
		},
		[postValidateOpt.fulfilled]: (state, { payload }) => {
			state.error = null;
			state.loading = false;
			state.userCurrent = payload;
		},
	},
});

export const { setSectionImage, setTitlePinCode, setRegisterUserData, setOtp, updateOtp, setLoading, setError } =
	Application.actions;

export default Application.reducer;
