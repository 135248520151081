export const TOKEN = 'intermediate_token';
export const INVITATION_TOKEN = 'invitation_token';
export const INVITATION_ROLE = 'invitation_role';
export const INVITATION_PROJECT_SLUG = 'invitation_project_slug';
export const SSO_STATE = 'vsSsoState';
export const RETURN_URL = 'return_url';
export const OTP_VALUES = 'otp';
export const LOGIN_GOOGLE_UUID_KEY = 'stateGgl';
export const LOGIN_MICROSOFT_UUID_KEY = 'stateMs';
export const SOCIAL_LOGIN_ERROR = 'social_login_error';
export const SOCIAL_AUTH_PROVIDER = 'provider';
export const LAST_SELECTED_SLUG = 'lastSelectedSlug';
export const SOCIAL_AUTH_REGISTER = 'social_register';
